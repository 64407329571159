.container {
  width: 80%;
  margin: 2rem auto;
  /*border:1px solid orange;*/
}

.title {
  text-align: center;
}

.firstBlock {
  border: 1px solid black;
  color: white;
}

.firstBlockContent {
  text-align: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.callButton {
  background: #fbc318;
  width: fit-content;
  curspor: pointer;
  color: black;
}

.callButton > .number {
  font-size: 2em;
}

.contactButton {
  background: #fbc318;
  width: fit-content;
  color: black;
}

.contactButton:hover,
.callButton:hover {
  background: #c79b15;
}

.secondBlock {
  padding: 2em;
  background: white;
  border: 1px solid black;
  text-align: center;
  color: #4d4d4d;
}

.secondBlock > h2 {
  font-weight: lighter;
}

.blackBlock {
  color: white;
  background-color: black;
}

.bgOne {
  background-image: url("/wallpaper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
